import React, { useEffect, useState } from 'react';
import { ChakraProvider, Box, Flex } from '@chakra-ui/react';
import { Route, Routes, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import Sponsors from './pages/Sponsors/Sponsors';
import Countdown from './pages/Countdown/Countdown';
import Contact from './pages/Contact/Contact';
import Events from './pages/Events/Events';
import MyProfile from './pages/newProfile/MyProfile';
import CompleteLoginInfo from './pages/newProfile/CompleteLoginInfo';
import ProfileService from './pages/newProfile/services/ProfileService';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navbar from './common/components/Navbar/Navbar';
import Footer from './common/components/Footer/Footer';
import { NotFound } from './pages/NotFound';
import theme from './common/styles/CustomTheme';
import Fonts from './common/styles/fonts';
import { useAuth } from 'react-oidc-context';
import Home from './pages/Home/Home';
import './App.css';
import { pageToUrl, AdminPage } from 'admin_lsac';
import { pages } from './pages/AdminPanel/schema.config';

const queryClient = new QueryClient();
const showDisplay = process.env.REACT_APP_DISPLAY_APP === 'true';
const showRegister = process.env.REACT_APP_REGISTER === 'true';

interface AppRoutesProps {
  isProfileComplete: boolean | null;
}

const AppRoutes: React.FC<AppRoutesProps> = ({ isProfileComplete }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/adm_panel');

  return (
    <>
      <Navbar />
      {isAdminRoute && localStorage.getItem('role') === 'ADMIN' ? (
        <>
          <Box h={showRegister ? '90px' : '150px'} />
          <Routes>
            {pages.map((page, index) => (
              <Route
                key={index}
                path={`adm_panel/${pageToUrl(page)}`}
                element={<AdminPage pages={pages} selectedPage={page} basePath="/adm_panel" />}
              />
            ))}
            {pages[0] ? (
              <Route
                path={`adm_panel/*`}
                element={<AdminPage pages={pages} selectedPage={pages[0]} basePath="/adm_panel" />}
              />
            ) : null}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      ) : (
        <Flex
          minHeight={'100vh'}
          flexDirection={'column'}
          overflow="hidden"
          justifyContent={'space-between'}
        >
          <Box h={showRegister ? '90px' : '150px'} />
          <Routes>
            <Route
              path="/"
              element={isProfileComplete === false ? <Navigate to="/complete-profile" /> : <Home />}
            />
            <Route path="/complete-profile" element={<CompleteLoginInfo />} />
            {showRegister && (
              <>
                <Route path="events" element={<Events />} />
                <Route path="sponsors" element={<Sponsors />} />
                <Route path="countdown" element={<Countdown />} />
                <Route path="profile" element={<MyProfile />} />
              </>
            )}
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Flex>
      )}
    </>
  );
};

export const App = () => {
  const auth = useAuth();
  const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(null);

  useEffect(() => {
    const checkProfileCompletion = async () => {
      if (auth.user) {
        try {
          const complete = await ProfileService.isProfileCompleted(auth.user.access_token);
          const userRole = await ProfileService.getUserRole(auth.user.access_token);
          setIsProfileComplete(complete);
          localStorage.setItem('role', userRole);
          if (userRole === "ADMIN") {
            localStorage.setItem('access_token', auth.user.access_token);
          }
        } catch (error) {
          console.error('Error checking profile completion:', error);
        }
      }
    };

    checkProfileCompletion();
  }, [auth.user]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        {showDisplay ? (
          <BrowserRouter>
            <AppRoutes isProfileComplete={isProfileComplete} />
          </BrowserRouter>
        ) : (
          <Countdown />
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
};
