import React, { useEffect, useState } from 'react';
import One from '../assets/image 1.png';
import Two from '../assets/image 2.png';
import Three from '../assets/image 3.png';
import Four from '../assets/image 4.png';
import Five from '../assets/image 5.png';
import Six from '../assets/image 6.png';
import Seven from '../assets/image 7.png';
import Eight from '../assets/image 8.png';
import Nine from '../assets/image 9.png';
import Zero from '../assets/image 0.png';
import Dots from '../assets/image.png';
import { Box, VStack, Text, Image, HStack, position } from '@chakra-ui/react';
import Background from '../assets/background.png';
import HackLogo from '../../../common/assets/hackitall.png';
import Ring from '../assets/Ring.png';
import Highlight from '../assets/Highlights.png';
import { keyframes } from '@emotion/react';

const toImage = (time: string) => {
  switch (time) {
    case '1':
      return One;
    case '2':
      return Two;
    case '3':
      return Three;
    case '4':
      return Four;
    case '5':
      return Five;
    case '6':
      return Six;
    case '7':
      return Seven;
    case '8':
      return Eight;
    case '9':
      return Nine;
    case '0':
      return Zero;
  }
};
const CountdownRing = () => {
  const start = new Date(process.env.REACT_APP_REGISTER_DATE || '2024-11-04T10:00:00Z');
  const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
`;

  const [time, setTime] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    // Disable horizontal scrolling
    document.body.style.overflowX = 'hidden';

    const interval = setInterval(() => {
      const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Bucharest' }));
      let difference = start.getTime() - now.getTime();

      if (difference < 0) difference = 0;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTime({
        days: days.toString().padStart(2, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      // Restore the original overflow style
      document.body.style.overflowX = 'auto';
    };
  }, [start]);

  const letterSize = '2.25%';

  return (
    <VStack
      width={['160vw', '140vw', '98vw']}
      alignContent="center"
      position="relative"
      top="12.5vh"
    >
      <Image
        src={Highlight}
        position="absolute"
        width={'100%'}
        style={{ filter: 'brightness(1.4)' }}
        animation={`${pulse} 4s infinite`}
      />
      <Image src={Ring} width={'100%'} style={{ filter: 'brightness(1.4)' }} />
      <HStack
        spacing={0}
        width="100%"
        justifyContent="center"
        position="absolute"
        top="36%"
        right="0.6vw"
      >
        <Image src={toImage(time.days[0])} width={letterSize} />
        <Image src={toImage(time.days[1])} width={letterSize} />

        <Image src={Dots} width={letterSize} />

        <Image src={toImage(time.hours[0])} width={letterSize} />
        <Image src={toImage(time.hours[1])} width={letterSize} />

        <Image src={Dots} width={letterSize} />

        <Image src={toImage(time.minutes[0])} width={letterSize} />
        <Image src={toImage(time.minutes[1])} width={letterSize} />

        <Image src={Dots} width={letterSize} />

        <Image src={toImage(time.seconds[0])} width={letterSize} />
        <Image src={toImage(time.seconds[1])} width={letterSize} />
      </HStack>
    </VStack>
  );
};

export default CountdownRing;
