import React from 'react';
import SponsorCard from './conponents/SponsorCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import {
  VStack,
  HStack,
  Text,
  Box,
  Image,
  IconButton,
  useBreakpointValue,
  Grid,
} from '@chakra-ui/react';
import Next from '../../common/assets/Next.png';
import Prev from '../../common/assets/Previous.png';
import { ArrowButton } from '../../common/components/Button/ArrowIconButton';

import DB from './assets/Logos/DB.png';
import Optiver from './assets/Logos/Optiver.png';
import Stripe from './assets/Logos/Stripe.png';
import AD01 from './assets/Logos/AD01.png';

const SponsorList = [
  {
    name: 'Optiver',
    sponsors: [
      {
        type: 'Main',
        description:
          'Optiver este un lider global în market making, dedicat furnizării de lichiditate pe piețele financiare internaționale. Cu birouri în orașe strategice precum Amsterdam și Chicago, compania utilizează tehnologie avansată pentru a facilita tranzacții eficiente în acțiuni, opțiuni și valute. Angajamentul său față de excelență și diversitate în echipă contribuie la abordarea provocărilor financiare în timp real. De asemenea, Optiver Foundation susține proiecte cu impact global pentru a promova schimbări durabile în comunități​.',
        logo: Optiver,
        link: 'https://optiver.com/',
      },
    ],
  },
  {
    name: 'AD/01',
    sponsors: [
      {
        type: 'Main',
        description:
          'AD/01 își propune să redefinească retailul alimentar prin tehnologie inteligentă, contribuind la crearea unor experiențe de cumpărare mai intuitive și eficiente pentru clienți. Prin investiții continue în soluții digitale și optimizări ale lanțului de aprovizionare, AD/01 aduce progres în fiecare etapă a interacțiunii cu clienții, de la selectarea produselor până la livrare. Ca parte a grupului internațional Ahold Delhaize, AD/01 beneficiază de resursele și expertiza unei rețele globale, concentrându-se pe dezvoltarea unor soluții ce transformă cumpărăturile zilnice într-o experiență simplificată și adaptată nevoilor moderne​.',
        logo: AD01,
        link: 'https://www.ad01.com/',
      },
    ],
  },
  {
    name: 'Stripe',
    sponsors: [
      {
        type: 'Main',
        description:
          'Stripe își propune să revoluționeze modul în care companiile acceptă plăți online, facilitând transferul rapid și sigur de fonduri prin intermediul cardurilor de credit, debit și a altor metode alternative. Cu un set variat de instrumente dedicate facturării și gestionării subscripțiilor, Stripe se adaptează nevoilor afacerilor moderne, oferind soluții personalizabile care optimizează experiența utilizatorilor. În plus, printr-o abordare prietenoasă pentru dezvoltatori, Stripe simplifică integrarea sistemelor de plată, sprijinind astfel creșterea veniturilor și eficientizarea proceselor financiare ale clienților​.',
        logo: Stripe,
        link: 'https://stripe.com/',
      },
    ],
  },
  {
    name: 'Deutsche Bank',
    sponsors: [
      {
        type: 'Main',
        description:
          'Deutsche Bank contribuie la transformarea serviciilor bancare globale prin soluții integrate de investiții, management al activelor și consultanță financiară. Cu o rețea puternică în Europa, Asia și America, banca promovează un model de afaceri responsabil, aliniat standardelor ESG (Environmental, Social, Governance), pentru a sprijini o economie sustenabilă și inovativă. În plus, Deutsche Bank investește în digitalizare și tehnologii avansate, adaptându-și serviciile pentru a răspunde mai bine nevoilor clienților moderni​.',
        logo: DB,
        link: 'https://www.db.com',
      },
    ],
  },
];

const MediaSponsors = [
  'https://www.microsoft.com/favicon.ico',
  'https://www.microsoft.com/favicon.ico',
  'https://www.microsoft.com/favicon.ico',
  'https://www.microsoft.com/favicon.ico',
  'https://www.microsoft.com/favicon.ico',
  'https://www.microsoft.com/favicon.ico',
];

interface Sponsor {
  type: string;
  description: string;
  logo: string;
  link: string;
}

interface Event {
  name: string;
  sponsors: Sponsor[];
}

const Sponsors = () => {
  const cards = SponsorList.map((event: Event) => {
    return event.sponsors.map((sponsor: Sponsor) => {
      return (
        <SponsorCard
          key={sponsor.type}
          type={sponsor.type}
          event={event.name}
          description={sponsor.description}
          logo={sponsor.logo}
          link={sponsor.link}
        />
      );
    });
  });
  return (
    <VStack>
      <Text fontFamily="Cocogoose" fontSize={['8vw', '4vw']} my="10vh">
        Sponsori probe
      </Text>
      <Grid
        justifyContent="space-evenly"
        width="100vw"
        mb="10vh"
        px="10vw"
        rowGap="20vh"
        columnGap="20vw"
        templateColumns={['repeat(1,1fr)', 'repeat(1,1fr)', 'repeat(2,1fr)', 'repeat(2, 1fr)']}
      >
        {cards}
      </Grid>

      {/* <Text fontFamily="Cocogoose" fontSize={['8vw', '4vw']} my="10vh">
        {' '}
        Sponsori media
      </Text>
      <Box width="80vw" mb="10vh">
        <Slider
          swipeToSlide={true}
          slidesToShow={useBreakpointValue({ base: 1, md: 2, lg: 4 })}
          nextArrow={<ArrowButton type="right" src={Next} />}
          prevArrow={<ArrowButton type="left" src={Prev} />}
        >
          {MediaSponsors.map((sponsor, index) => (
            <Box key={index} height="30vh">
              <VStack justifyContent="center" width="100%" height="30vh" alignItems="center">
                <Image
                  src={sponsor}
                  alt={`Sponsor media ${index}`}
                  boxSize="200px"
                  borderRadius="full"
                  backgroundColor="#001C28"
                  filter={'drop-shadow(0px 0px 10px #015688) '}
                  border="3px solid #015688"
                />
              </VStack>
            </Box>
          ))}
        </Slider>
      </Box> */}
    </VStack>
  );
};

export default Sponsors;
