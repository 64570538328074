import Utils from '../../../common/utils';
import {
  CREATE_TEAM_URL,
  JOIN_TEAM_URL,
  LEAVE_TEAM_URL,
  PROFILE_URL,
  DELETE_PROFILE_URL,
  EVENTS_URL,
  TEAM_INFO_URL,
  REMOVE_TEAM_MEMBER_CAPTAIN_URL,
  UPDATE_TEAM_URL,
  DOWNLOAD_CV_URL,
} from '../../../common/constants';

function getPrefferedEventsId(events: any, rankings: number[]) {
  const filteredRankings: number[] = rankings.map((rank: number) => (isNaN(rank) ? 0 : rank));

  const eventsPrefference = filteredRankings.map((rank: number, idx) => {
    return { id: events[idx].id, eventName: events[idx].name, rank: rank };
  });

  const sortedPrefferences = eventsPrefference
    .sort((a, b) => a.rank - b.rank)
    .filter(event => event.rank !== 0)
    .map(event => event.id);

  return sortedPrefferences;
}

const ProfileService = {
  getUserInfo: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await Utils.GetRequest(PROFILE_URL, headers);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
  isInTeam: async (token: string) => {
    const userInfo = await ProfileService.getUserInfo(token);
    return !!userInfo.teamId;
  },

  fetchEvents: async (token: string) => {
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const response = await Utils.GetRequest(EVENTS_URL, header);
    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },

  createTeam: async (token: string, teamName: string, githubPage: string, rankings: number[]) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      responseType: 'blob',
    };

    const events = await ProfileService.fetchEvents(token);
    const eventsPrefferenceIds = getPrefferedEventsId(events, rankings);

    const data = {
      name: teamName,
      githubUrl: githubPage,
      eventList: eventsPrefferenceIds,
    };

    const response = await Utils.PostRequest(CREATE_TEAM_URL, data, headers);

    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },

  updateTeam: async (token: string, teamName: string, githubPage: string, rankings: number[]) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log(rankings);

    const events = await ProfileService.fetchEvents(token);
    const eventsPrefferenceIds = getPrefferedEventsId(events, rankings);
    console.log(eventsPrefferenceIds);

    const data = {
      name: teamName,
      githubUrl: githubPage,
      eventList: eventsPrefferenceIds,
    };

    const response = await Utils.PutRequest(`${UPDATE_TEAM_URL}`, data, headers);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response);
    }
  },
  fetchTeamData: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await Utils.GetRequest(TEAM_INFO_URL, headers);

    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },

  updateUser: async (
    token: string,
    shirtSize: string,
    diet: string,
    allergies: string,
    cv: File | null,
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };

    const data = {
      shirtSize: shirtSize,
      diet: diet,
      allergies: allergies,
      file: cv,
    };

    const response = await Utils.PostRequest(PROFILE_URL, data, headers);

    console.log(data);
    if (response.status < 300) {
      return response.data;
    } else {
      throw new Error(response);
    }
  },
  joinTeam: async (token: string, teamId: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await Utils.PostRequest(`${JOIN_TEAM_URL}${teamId}`, {}, headers);
    if ((response.status as number) < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
  leaveTeam: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await Utils.DeleteRequest(LEAVE_TEAM_URL, headers);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
  kickMember: async (token: string, member: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await Utils.DeleteRequest(
      `${REMOVE_TEAM_MEMBER_CAPTAIN_URL}${member}`,
      headers,
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
  deleteAccount: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await Utils.DeleteRequest(DELETE_PROFILE_URL, headers);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
  isProfileCompleted: async (token: string) => {
    const userInfo = await ProfileService.getUserInfo(token);
    return userInfo.profileCompleted;
  },
  getUserRole: async (token: string) => {
    const userInfo = await ProfileService.getUserInfo(token);
    return userInfo.role;
  },

  getUserCV: async (token: string) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
    };

    const response = await Utils.GetRequest(DOWNLOAD_CV_URL, headers, 'blob');

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  },
};

export default ProfileService;
