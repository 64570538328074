import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
  Textarea,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
} from '@chakra-ui/react';
import { useAuth } from 'react-oidc-context';
import ProfileService from '../services/ProfileService';
import { saveAs } from 'file-saver';

interface UpdateProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmClose: () => void;
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({
  isOpen,
  onClose,
  onConfirmClose,
}) => {
  const shirtSizes = ['S', 'M', 'L', 'XL'];

  const dietOptions = [
    { value: 'NON_VEGAN', label: 'Non Vegan' },
    { value: 'VEGETARIAN', label: 'Vegetarian' },
    { value: 'VEGAN', label: 'Vegan' },
  ];

  const [shirtSize, setShirtSize] = useState('M');
  const [diet, setDiet] = useState('NON_VEGAN');
  const [allergies, setAllergies] = useState('');
  const [cv, setCv] = useState<File | null>(null);
  const [hasCv, setHasCv] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [postCV, setPostCV] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = auth.user?.access_token ?? '';
        const userInfo = await ProfileService.getUserInfo(token);

        setShirtSize(userInfo.shirtSize);
        setDiet(userInfo.diet);
        setAllergies(userInfo.alergies);
        setHasCv(!!userInfo.CV);
      } catch (error) {
        setError('Failed to fetch user information.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchUserInfo();
    } else {
      setError(''); // Reset error message when modal closes
    }
  }, [isOpen, auth]);

  const handleSave = async () => {
    setError(''); // Clear error message before attempting to save
    try {
      const token = auth.user?.access_token ?? '';
      await ProfileService.updateUser(token, shirtSize, diet, allergies, cv);
      onClose(); // Close the modal after saving
    } catch (error) {
      setError('Failed to update user information.');
      console.error(error);
    } finally {
      onConfirmClose(); // Reset the modal state
    }
  };

  const handleCvDownload = async () => {
    try {
      const token = auth.user?.access_token ?? '';
      const res = await ProfileService.getUserCV(token);

      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CV.pdf'); // Change to the actual filename and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError('Failed to download CV.');
      console.error(error);
    }
  };

  if (loading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Spinner size="xl" />
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent bg="#021a28" color="white" borderRadius="20px" p="20px" width="900px">
        <ModalHeader fontWeight="bold" fontSize="2xl" textAlign="center">
          Modificare date
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && (
            <Alert status="error" mb="1rem" color={'black'} backgroundColor="red">
              <AlertIcon color="black" />
              {error}
            </Alert>
          )}

          {/* Shirt size selection */}
          <FormControl mb="1.5rem" display="flex" alignItems="center">
            <FormLabel mb="0" mr="1rem">
              Mărime tricou:
            </FormLabel>
            <Select
              bg="#0277bd"
              color="white"
              borderRadius="30px"
              border="none"
              width="100px"
              value={shirtSize}
              onChange={e => setShirtSize(e.target.value)}
              sx={{
                option: {
                  backgroundColor: '#0277bd',
                  color: 'white',
                },
              }}
            >
              {shirtSizes.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* CV Upload */}
          <Box
            display="flex"
            alignItems="center"
            mb="1.5rem"
            flexDirection="column"
            placeItems="start"
          >
            <HStack alignContent="center" justifyContent="center">
              <FormLabel mb="1" flex="1">
                CV:
              </FormLabel>
              {hasCv && (
                <>
                  <Button colorScheme="blue" onClick={handleCvDownload}>
                    Get CV
                  </Button>
                  <Button colorScheme="blue" onClick={() => setPostCV(true)}>
                    Post new CV
                  </Button>
                </>
              )}
            </HStack>
            {(postCV || !hasCv) && (
              <Input
                type="file"
                w="100%"
                mt="2vh"
                height="10vh"
                onChange={e => setCv(e.target.files?.[0] || null)}
              />
            )}
          </Box>

          {/* Diet selection */}
          <FormControl mb="1.5rem">
            <FormLabel>Regim alimentar:</FormLabel>
            <Select
              size="sm"
              bg="#0277bd"
              color="white"
              borderRadius="30px"
              border="none"
              value={diet}
              onChange={e => setDiet(e.target.value)}
              sx={{
                option: {
                  backgroundColor: '#0277bd',
                  color: 'white',
                },
              }}
            >
              {dietOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Allergies Textarea */}
          <FormControl mb="1.5rem">
            <FormLabel>Alte preferințe alimentare:</FormLabel>
            <Textarea
              placeholder={allergies}
              borderRadius="20px"
              value={allergies}
              onChange={e => setAllergies(e.target.value)}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose} position="absolute" left="2vw">
            Anuleaza
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Salvează
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateProfileModal;
