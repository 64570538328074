import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Text,
  Input,
} from '@chakra-ui/react';
import ProfileService from './services/ProfileService';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const CompleteLoginInfo: React.FC = () => {
  const shirtSizes = ['S', 'M', 'L', 'XL'];

  const dietOptions = [
    { value: 'VEGAN', label: 'Vegan' },
    { value: 'VEGETARIAN', label: 'Vegetarian' },
    { value: 'NON_VEGAN', label: 'Non Vegan' },
  ];

  const [shirtSize, setShirtSize] = useState('M');
  const [diet, setDiet] = useState('NON_VEGAN');
  const [allergies, setAllergies] = useState('');
  const [cv, setCv] = useState<File | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSave = async () => {
    const token = auth.user?.access_token ?? '';
    try {
      console.log('Alergies:', allergies);
      setIsSaving(true);
      await ProfileService.updateUser(token, shirtSize, diet, allergies, cv);
      window.location.href = '/profile';
    } catch (error) {
      setIsSaving(false);
      navigate('/');
    }
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color="white"
      p="20px"
      textAlign="center"
    >
      <Text fontSize="4xl" mb="2rem" fontWeight="bold">
        Completează-ți informațiile
      </Text>
      <Box
        bg="#021a28"
        color="white"
        borderRadius="20px"
        p="20px"
        minWidth={['90vw', '80vw', '60vw', '50vw']}
        maxWidth="900px"
        mx="auto"
        mt="50px"
      >
        <FormControl mb="1.5rem" display="flex" alignItems="center">
          <FormLabel mb="0" mr="1rem">
            Mărime tricou:
          </FormLabel>
          <Select
            bg="#0277bd"
            color="white"
            borderRadius="30px"
            border="none"
            width="100px"
            value={shirtSize}
            onChange={e => setShirtSize(e.target.value)}
            sx={{
              option: {
                backgroundColor: '#021a28',
                color: 'white',
              },
            }}
          >
            {shirtSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* CV Upload */}
        <Box
          display="flex"
          alignItems="center"
          mb="1.5rem"
          flexDirection="column"
          placeItems="start"
        >
          <FormLabel mb="1" flex="1">
            CV:
          </FormLabel>
          <Input
            type="file"
            maxWidth="fit-content"
            height="10vh"
            onChange={e => setCv(e.target.files?.[0] || null)}
          />
        </Box>

        <FormControl mb="1.5rem">
          <FormLabel>Regim alimentar:</FormLabel>
          <Select
            size="sm"
            bg="#0277bd"
            color="white"
            borderRadius="30px"
            border="none"
            value={diet}
            onChange={e => setDiet(e.target.value)}
            sx={{
              option: {
                backgroundColor: '#021a28',
                color: 'white',
              },
            }}
          >
            {dietOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl mb="1.5rem">
          <FormLabel>Alte preferințe alimentare/ alergii:</FormLabel>
          <Textarea
            placeholder="Optional"
            borderRadius="20px"
            onChange={e => setAllergies(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSave} isLoading={isSaving}>
          Salvează
        </Button>
      </Box>
    </Box>
  );
};

export default CompleteLoginInfo;
